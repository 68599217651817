import dayjs from 'dayjs'; // dayjs 임포트 추가
/** Key, Value를 받아 Cookie 생성 후 저장
 * @param {string} name
 * @param {string} value
 */

export const setCookie = (name: string, value: string, max: number = 864000) => {
  const expires = dayjs().add(max, 'second'); // dayjs로 변경
  document.cookie = `${name}=${value}; expires=${expires}; path=/;`;
};

/** Key를 받아 Value를 반환
 * @param {string} name
 * @returns {string}
 */
export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

/** Key를 받아 쿠키를 삭제
 * @param {string} name
 */
export const removeCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

/** 쿠키 존재 여부 확인
 * @param {string} name
 * @returns {boolean}
 */
export const hasCookie = (name: string) => {
  return document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`));
};
