import AuthInput from 'components/style/AuthInput';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import AuthButton from 'components/style/AuthButton';
import { Link, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import validator from 'validator';
import { ERROR_MESSAGE, PASSWORD_REGEX, PASSWORD_REGEX_ERROR, SIGNUP_SUCCESS_MESSAGE } from 'lib/constants';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import OneBtnModal from 'components/common/OneBtnModal';
import useModalStore from 'store/useModalStore';
import Loader from 'components/common/Loader';
import { ERROR_CODES } from 'lib/error';

type Props = {};

const checkPasswords = ({ password, confirmPassword }: { password: string; confirmPassword: string }) =>
  password === confirmPassword;

const signUpSchema = z
  .object({
    email: z.string().email({ message: '이메일을 입력해주세요' }),
    authCode: z.string().trim().min(1, { message: '인증번호를 입력해주세요' }),
    password: z.string().trim().regex(PASSWORD_REGEX, PASSWORD_REGEX_ERROR),
    confirmPassword: z.string().trim().min(1, { message: '비밀번호를 입력해주세요' }),
    restaurantName: z.string().trim().min(1, { message: '레스토랑 이름을 입력해주세요' }),
    restaurantPhone: z.string(),
    managerPhone: z
      .string()
      .trim()
      .refine((phone) => validator.isMobilePhone(phone, 'ko-KR'), {
        message: '올바른 휴대폰 번호를 입력해주세요',
      }),
  })
  .refine(checkPasswords, {
    message: '비밀번호가 일치하지 않습니다',
    path: ['confirmPassword'],
  });

export default function SignUp({}: Props) {
  const navigate = useNavigate();
  const [alertMsg, setAlertMsg] = useState('');
  const { modals, setStatus } = useModalStore();
  const { mutate, isPending } = useMutation<any, ApiError, ApiProps>({ mutationFn: api });
  const [isCodeVerification, setIsCodeVerification] = useState({
    send: false,
    certification: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm<z.infer<typeof signUpSchema>>({
    resolver: zodResolver(signUpSchema),
  });

  // 인증 요청
  const onSendCode = () => {
    const email = getValues('email');
    if (!validator.isEmail(email)) {
      setError('email', {
        type: 'custom',
        message: '유효하지 않은 이메일입니다',
      });
      return;
    } else {
      clearErrors();

      if (isCodeVerification.send) {
        setIsCodeVerification((state) => {
          return { certification: false, send: false };
        });
        setValue('authCode', '');
      } else {
        mutate(
          {
            method: 'POST',
            url: '/auth/code',
            data: {
              email: getValues('email'),
            },
          },
          {
            onSuccess(data, variables, context) {
              setIsCodeVerification((state) => {
                return { ...state, send: true };
              });
            },
            onError(error, variables, context) {
              setAlertMsg(ERROR_MESSAGE);
            },
          }
        );
      }
    }
  };

  // 인증 확인
  const checkCode = () => {
    if (isCodeVerification?.certification) {
      return;
    }

    const email = getValues('email');
    const authCode = getValues('authCode');

    if (authCode.length > 0) {
      clearErrors();
      mutate(
        {
          method: 'POST',
          url: '/auth/code/verification',
          data: {
            email,
            authCode,
          },
        },
        {
          onSuccess(data, variables, context) {
            setIsCodeVerification({
              certification: true,
              send: true,
            });
          },
          onError(error, variables, context) {
            setError('authCode', {
              type: 'custom',
              message: '인증번호가 일치하지 않습니다',
            });
          },
        }
      );
    } else {
      setError('authCode', {
        type: 'custom',
        message: '인증번호를 입력해주세요',
      });
    }
  };

  // 회원가입
  const onSubmit = (data: z.infer<typeof signUpSchema>) => {
    const { email, authCode, confirmPassword, managerPhone, password, restaurantName, restaurantPhone } = data;
    mutate(
      {
        method: 'POST',
        url: '/auth/sign-up',
        data: {
          email,
          password,
          restaurantName,
          restaurantPhone,
          managerPhone,
          authCode,
        },
      },
      {
        onSuccess(data, variables, context) {
          setAlertMsg(SIGNUP_SUCCESS_MESSAGE);
        },
        onError(error, variables, context) {
          if (ERROR_CODES[error.error.code]) {
            setAlertMsg(ERROR_CODES[error.error.code]);
          } else {
            setAlertMsg(ERROR_MESSAGE);
          }
        },
        onSettled(data, error, variables, context) {
          setStatus('oneBtnModal');
        },
      }
    );
  };

  return (
    <div className='flex flex-col justify-center items-center gap-[50px] w-full h-full'>
      {modals.oneBtnModal.isOpen && (
        <OneBtnModal
          title='안내'
          contents={alertMsg}
          onClick={() => {
            if (alertMsg === SIGNUP_SUCCESS_MESSAGE) {
              navigate('/admin/login');
            }
          }}
        />
      )}
      {isPending && <Loader />}

      <LogoSvg width={88} height={18} className='hidden md:block' />

      <form className='flex flex-col gap-[15px] max-w-[335px] w-full' onSubmit={handleSubmit(onSubmit)}>
        {/* 이메일 */}
        <div className='w-full'>
          <div className='flex items-center justify-between w-full gap-3'>
            <AuthInput
              {...register('email')}
              placeholder='이메일 (아이디)'
              required={true}
              type='email'
              readOnly={isCodeVerification?.send}
            />
            <button
              type='button'
              className='min-w-[100px] h-full py-2 px-4 font-semibold text-[16px] rounded-lg bg-gray2 text-white'
              onClick={onSendCode}
            >
              {isCodeVerification?.send ? '재요청' : '인증요청'}
            </button>
          </div>
          {errors.email?.message && <span className='text-red-500 text-sm mt-1 pl-1'>{errors.email?.message}</span>}
        </div>
        {/* 인증 코드 */}
        {isCodeVerification.send && (
          <div className='w-full'>
            <div className='flex items-center justify-between w-full gap-3'>
              <AuthInput
                {...register('authCode')}
                placeholder='인증번호'
                required={true}
                type='string'
                readOnly={isCodeVerification?.certification}
              />
              <button
                type='button'
                className='min-w-[100px] h-full py-2 px-4 font-semibold text-[16px] rounded-lg bg-gray2 text-white'
                onClick={checkCode}
              >
                {isCodeVerification?.certification ? '인증완료' : '인증확인'}
              </button>
            </div>
            {errors.authCode?.message && (
              <span className='text-red-500 text-sm mt-1 pl-1'>{errors.authCode?.message}</span>
            )}
          </div>
        )}
        <AuthInput
          {...register('password')}
          placeholder='비밀번호'
          required={true}
          type='password'
          error={errors.password?.message}
        />
        <AuthInput
          {...register('confirmPassword')}
          placeholder='비밀번호 확인'
          required={true}
          type='password'
          error={errors.confirmPassword?.message}
        />
        <AuthInput
          {...register('restaurantName')}
          placeholder='레스토랑 이름'
          required={true}
          type='text'
          error={errors.restaurantName?.message}
        />
        <AuthInput
          {...register('restaurantPhone')}
          placeholder='레스토랑 연락처 (선택)'
          required={true}
          type='text'
          error={errors.restaurantPhone?.message}
        />
        <AuthInput
          {...register('managerPhone')}
          placeholder='담당자 연락처'
          required={true}
          type='text'
          error={errors.managerPhone?.message}
        />

        <AuthButton style='mt-[21px] bg-lightGreen1 text-gray4' text='가입하기' type='submit' />
        <Link to={'/admin/login'}>
          <AuthButton style='mt-[1px] bg-gray1 text-gray4' text='로그인' type='button' />
        </Link>
      </form>
    </div>
  );
}
