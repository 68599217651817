type Props = {
  totalItems: number; // 전체 아이템 수
  itemsPerPage: number; // 페이지당 아이템 수
  currentPage: number; // 현재 페이지
  onPageChange: (page: number) => void; // 페이지 변경 핸들러
};

export default function Pagination({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}: Props) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const renderPageNumbers = () => {
    const pages = [];
    const pageGroup = Math.ceil(currentPage / 10);
    const startPage = (pageGroup - 1) * 10 + 1;
    const endPage = Math.min(startPage + 9, totalPages);

    // 페이지 번호 버튼
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`px-2 py-1 mx-1 rounded ${
            currentPage === i
              ? "border border-main1 text-main1 transition-opacity"
              : "border border-gray4 text-gray4 hover:bg-main1 hover:text-white hover:border-main1"
          }`}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="flex items-center justify-center space-x-2 my-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-4 py-1 rounded bg-gray1 hover:bg-gray-300 disabled:opacity-50"
      >
        {"이전"}
      </button>

      {renderPageNumbers()}

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-4 py-1 rounded bg-gray1 hover:bg-gray-300 disabled:opacity-50"
      >
        {"다음"}
      </button>
    </div>
  );
}
