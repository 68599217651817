import CustomButton from 'components/style/Button';
import CustomInput from 'components/style/Input';
import useBodyOverflow from 'hooks/useBodyOverflow';
import { useRef, useState } from 'react';
import useModalStore from 'store/useModalStore';

type Props = {
  addTags: (tag: string) => void;
};

export default function CreateTagModal({ addTags }: Props) {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const { setStatus } = useModalStore();
  const handleClick = () => setStatus('createTagModal');
  const [tag, setTag] = useState<string>();

  return (
    <div className='modal-bg z-[51]' ref={bgRef} onClick={handleClick}>
      <div
        className='relative shadow-xl rounded-2xl bg-white py-4 px-4 border border-gray-100'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='flex items-center justify-between gap-9'>
          <label className='text-[14px] font-medium text-nowrap'>태그 이름</label>
          <CustomInput placeholder='태그' required type='text' value={tag} onChange={(e) => setTag(e.target.value)} />
        </div>

        <div className='flex items-center justify-between gap-9 mt-4'>
          <CustomButton
            text='추가'
            onClick={() => {
              addTags(tag);
              handleClick();
            }}
            style='bg-main1 text-white '
          />
          <CustomButton text='취소' onClick={handleClick} style='bg-gray1 text-white ' />
        </div>
      </div>
    </div>
  );
}
