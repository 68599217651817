export const ERROR_CODES = {
  E01000: '회원이 이미 존재합니다',
  E01001: '회원이 존재하지 않습니다',
  E01002: '비밀번호가 일치하지 않습니다',
  E01003: '로그인 정보가 존재하지 않습니다',
  E01004: '인증 기록이 존재하지 않습니다',
  E01005: '유효시간이 초과되었습니다',
  E01006: '승인되지 않은 회원입니다',
  E02000: '존재하지 않는 레스토랑입니다',
};
