import Pagination from "components/common/Pagination";
import React, { useState } from "react";

type Props = {};

const List = [
  {
    email: "aaa@gmail.com",
    restaurantName: "청춘어랑",
    restaurantPhoneNumber: "02-1234-5678",
    managerPhoneNumber: "010-1113-34321",
    partnerApplication: false,
  },
];

export default function Partners({}: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // 페이지당 보여줄 아이템 수

  // 현재 페이지에 해당하는 아이템들만 계산 (temp)
  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = Array(100).fill(List[0]).slice(startIndex, endIndex);
    console.log("result", result);
    return result;
  };

  return (
    <div className="w-full">
      {/* title */}
      <li className="flex justify-between items-center pb-[25px] font-bold *:text-center">
        <span className="flex-[0.7]">번호</span>
        <span className="flex-1">아이디</span>
        <span className="flex-1">레스토랑 이름</span>
        <span className="flex-1">레스토랑 전화번호</span>
        <span className="flex-1">담당자 전화번호</span>
        <span className="flex-1">입점 신청</span>
      </li>

      {/* contents */}
      <ul className="flex flex-col gap-4">
        {getCurrentItems().map((list, index) => (
          <li
            key={index}
            className="flex justify-between items-center text-gray2 *:text-center"
          >
            <span className="flex-[0.7]">
              {(currentPage - 1) * itemsPerPage + index + 1}
            </span>
            <span className="flex-1">{list.email}</span>
            <span className="flex-1">{list.restaurantName}</span>
            <span className="flex-1">{list.restaurantPhoneNumber}</span>
            <span className="flex-1">{list.managerPhoneNumber}</span>
            <span className="flex-1">
              <button
                onClick={() => alert("개발중")}
                className={`${!list.partnerApplication && "cursor-pointer"}`}
              >
                {list.partnerApplication ? "승인" : "대기"}
              </button>
            </span>
          </li>
        ))}
      </ul>

      <div className="mt-[50px]">
        <Pagination
          totalItems={150}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
}
