import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";

type ModalLayoutProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const ModalLayout = ({ onClose, children, isOpen }: ModalLayoutProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="modal-bg items-center justify-end"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={(e) => {
            if (e.currentTarget === e.target) {
              onClose();
            }
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 500 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 500 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="w-full h-[80%] bg-white px-5 py-4 rounded-t-[8px]"
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalLayout;
