import { ReactComponent as PhonBackground } from "assets/svg/phonBackground.svg";
import { ReactComponent as GlobalIcon } from "assets/svg/global.svg";
import MoreSelect from "components/common/MoreSlect";
import { Link } from "react-router";
import { useState } from "react";
import DraggableCategory from "components/Drag/DraggableCategory";
import { Reorder } from "framer-motion";
import CreateCategoryModal from "components/menu/CreateCategoryModal";
import useModalStore from "store/useModalStore";
import CreateMenuModal from "components/menu/CreateMenuModal";

type Props = {};

const initialItems = ["메인", "사이드"];

export default function MenuDetail({}: Props) {
  const [items, setItems] = useState(initialItems);
  const { modals, setStatus } = useModalStore();

  return (
    <>
      {modals.createCategoryModal.isOpen && <CreateCategoryModal />}
      {modals.createMenuModal.isOpen && <CreateMenuModal />}
      {/* contents */}
      <div className="pt-[40px] flex-[2] px-[45px]">
        <div className="flex items-center justify-end pb-10">
          <button
            onClick={() => setStatus("createCategoryModal")}
            className="text-[16px] font-semibold rounded-md border py-1 px-2 text-main hover:text-main1 hover:transition-colors duration-300 hover:animate-pulse"
          >
            새 카테고리 만들기
          </button>
        </div>
        <section className="flex flex-col ">
          <ul className="flex flex-col gap-2">
            <li className="flex justify-between items-center">
              <span className="text-[16px] font-semibold">{`${"한국어"} 메뉴판`}</span>
              <span className="flex justify-start items-center gap-2">
                <GlobalIcon />
                <span className="w-[50px]">{"한국어"}</span>
                <span className="cursor-pointer">
                  <MoreSelect
                    list={[
                      {
                        label: "삭제하기",
                        name: "삭제하기",
                        onClick: () => alert("개발중"),
                      },
                    ]}
                  />
                </span>
              </span>
            </li>
          </ul>

          {/* menu List */}

          <Reorder.Group axis="y" onReorder={setItems} values={items}>
            <ul className="flex flex-col gap-6 mt-5">
              {items.map((item) => (
                <DraggableCategory item={item} key={item} />
              ))}
            </ul>
          </Reorder.Group>
        </section>
      </div>
      {/* preview */}
      <section className="pl-8 pt-4 border-l border-gray1">
        <span className="text-gray2">미리보기</span>
        <div className="mt-6 relative">
          <div className="absolute z-[-1]">
            <PhonBackground />
          </div>
          <div className="pt-[63px] pl-[24px] w-[210px] h-[470px] *:text-[12px] flex flex-col"></div>
        </div>
      </section>
    </>
  );
}
