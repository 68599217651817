// src/components/common/ErrorComponent.tsx
import React from 'react';

interface ErrorComponentProps {
  error: Error; // 오류 객체
  resetErrorBoundary: () => void; // 오류 리셋 함수
}

function ErrorComponent({ error, resetErrorBoundary }: ErrorComponentProps): JSX.Element {
  return (
    <div>
      <div>{error?.message}</div> {/* 오류 메시지 표시 */}
      <button type='button' onClick={resetErrorBoundary}>
        재시도
      </button>{' '}
      {/* 오류 리셋 버튼 */}
    </div>
  );
}

export default ErrorComponent;
