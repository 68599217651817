import CustomButton from 'components/style/Button';
import CustomInput from 'components/style/Input';
import useBodyOverflow from 'hooks/useBodyOverflow';
import React, { useEffect, useRef } from 'react';
import useModalStore from 'store/useModalStore';

type Props = {};

export default function CreateCategoryModal({}: Props) {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const { setStatus } = useModalStore();
  const handleClick = () => setStatus('createCategoryModal');

  return (
    <div className='modal-bg' ref={bgRef} onClick={handleClick}>
      <div
        className='relative shadow-xl rounded-2xl bg-white py-4 px-4 border border-gray-100'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='flex items-center justify-between gap-9'>
          <label className='text-[14px] font-medium text-nowrap'>카테고리 이름</label>
          <CustomInput placeholder='카테고리' required type='text' />
        </div>

        <div className='flex items-center justify-between gap-9 mt-4'>
          <CustomButton text='생성' onClick={() => alert('개발중')} style='bg-main1 text-white' />
          <CustomButton text='취소' onClick={handleClick} style='bg-gray1 text-white' />
        </div>
      </div>
    </div>
  );
}
