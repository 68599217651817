import React, { ButtonHTMLAttributes } from 'react';

type Props = {
  style?: string;
  text: string;
};

export default function AuthButton({ style, text, ...rest }: Props & ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...rest}
      className={`${style} w-full h-[48px] font-bold text-[14px] md:text-[16px] text-center rounded-[10px]`}
    >
      {text}
    </button>
  );
}
