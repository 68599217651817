import Button from 'components/style/Button';
import Input from 'components/style/Input';
import useBodyOverflow from 'hooks/useBodyOverflow';
import { useRef, useState } from 'react';
import useModalStore from 'store/useModalStore';
import CreateTagModal from './CreateTagModal';

type Props = {};

interface Options {
  name: string;
  price: string;
}

const tagList = ['치즈', '비건', '할랄'];

export default function CreateMenuModal({}: Props) {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const { modals, setStatus } = useModalStore();
  const handleClick = () => setStatus('createMenuModal');
  const [tags, setTags] = useState(tagList);
  const [options, setOptions] = useState<Options[]>([
    {
      name: '',
      price: '',
    },
  ]);

  const handleOptions = (index: number, type: 'name' | 'price', value: string) => {
    setOptions((prevOptions) => {
      const temp = [...prevOptions];
      if (temp[index]) {
        temp[index][type] = value;
      }
      return temp;
    });
  };

  const addOptions = () => {
    if (options.length < 5) setOptions((prevOptions) => [...prevOptions, { name: '', price: '' }]);
  };

  const removeOption = (index: number) => {
    setOptions((prevOptions) => {
      const temp = [...prevOptions];
      temp.splice(index, 1);

      return temp;
    });
  };

  const addTags = (tag: string) => {
    setTags((prevTag) => [...prevTag, tag]);
  };

  const removeTags = (index: number) => {
    setTags((prevTag) => {
      const temp = [...prevTag];
      temp.splice(index, 1);
      return temp;
    });
  };

  return (
    <>
      {modals.createTagModal.isOpen && <CreateTagModal addTags={addTags} />}
      <div className='modal-bg' ref={bgRef} onClick={handleClick}>
        <div
          className='scroll relative shadow-xl rounded-2xl bg-white py-12 px-8 border border-gray-100 max-h-[80%] w-[600px] overflow-y-scroll'
          onClick={(e) => e.stopPropagation()}
        >
          <div className='absolute top-2 right-2 cursor-pointer' onClick={handleClick}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
              />
            </svg>
          </div>

          <div className='flex items-center justify-between gap-9 mb-3'>
            <label className='text-[14px] font-medium text-nowrap w-10'>카테고리</label>
            <Input placeholder='카테고리' required type='text' readOnly value={'메인'} style='cursor-default' />
          </div>

          <div className='flex items-center justify-between gap-9'>
            <label className='text-[14px] font-medium text-nowrap w-10'>메뉴 이름</label>
            <Input placeholder='메뉴' required type='text' />
          </div>

          <div className='mt-10'>
            <h2 className='mb-4 text-[14px] font-bold'>메뉴 추가 옵션</h2>
            {options.map((options, index) => (
              <div
                key={index}
                className='relative flex items-center justify-center gap-8 border mb-4 last:mb-0 px-3 pt-5 py-3 rounded-lg'
              >
                <span
                  className='absolute top-2 right-2 cursor-pointer'
                  hidden={index === 0}
                  onClick={() => removeOption(index)}
                >
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='size-5'>
                    <path d='M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z' />
                  </svg>
                </span>
                <div className='flex flex-col gap-2'>
                  <label className='text-[14px] font-medium '>추가 옵션명</label>
                  <Input
                    placeholder='150g'
                    type='text'
                    required={false}
                    value={options.name}
                    onChange={(e) => handleOptions(index, 'name', e.target.value)}
                  />
                </div>
                <div className='flex flex-col gap-2'>
                  <label className="text-[14px] font-medium  after:content-['*'] after:text-red-500">가격</label>
                  <Input
                    placeholder='15,000원'
                    type='text'
                    required={true}
                    value={options.price}
                    onChange={(e) => handleOptions(index, 'price', e.target.value)}
                  />
                </div>
              </div>
            ))}

            <div className='mt-6'>
              <Button onClick={addOptions} text='메뉴 옵션 추가' style='bg-gray4 text-white' />
            </div>
          </div>

          <div className='mt-10'>
            <h2 className='mb-4 text-[14px] font-bold'>메뉴 이미지</h2>
            <label className='flex justify-center items-center aspect-video font-[16px] text-gray2 cursor-pointer border border-gray1 rounded-lg w-full'>
              <span className='font-semibold'>메뉴 이미지 업로드</span>
              <input type='file' accept='images/*' hidden />
            </label>
          </div>

          <div className='mt-10'>
            <div className='flex justify-between items-center mb-4 text-[14px] font-bold'>
              <h2>태그</h2>

              <Button
                onClick={() => setStatus('createTagModal')}
                text='태그 추가'
                style='bg-gray4 text-white max-w-[80px]'
              />
            </div>
            <div className='text-left aspect-video border border-gray1 rounded-lg w-full py-4 px-4'>
              <div className='flex flex-wrap gap-2'>
                {tags.map((tag, index) => (
                  <span
                    key={index}
                    className='h-[40px] font-[12px] py-2 px-3 border border-black rounded-md flex items-center justify-between gap-2'
                  >
                    {tag}
                    <span className='cursor-pointer' onClick={() => removeTags(index)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='size-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z'
                        />
                      </svg>
                    </span>
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className='mt-10'>
            <h2 className='mb-4 text-[14px] font-bold'>메뉴 설명</h2>
            <textarea
              placeholder='메뉴 설명'
              className='flex justify-center items-center aspect-video font-[16px] placeholder:text-gray2 placeholder:text-[16px] placeholder:font-semibold border border-gray1 rounded-lg w-full pl-3 pt-3'
            />
          </div>
          <div className='flex items-center justify-between gap-9 mt-16'>
            <Button text='생성' onClick={() => alert('개발중')} style='bg-main1 text-white ' />
            <Button text='취소' onClick={handleClick} style='bg-gray1 text-white ' />
          </div>
        </div>
      </div>
    </>
  );
}
