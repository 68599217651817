import React from 'react';
import { GridLoader } from 'react-spinners';

type Props = {};

export default function Loader({}: Props) {
  return (
    <div className='fixed top-0 left-0 w-screen h-screen flex flex-col justify-center items-center bg-[rgba(0,0,0,0.45)] z-[999999]'>
      <GridLoader size={20} color='#00E4A6' />
    </div>
  );
}
