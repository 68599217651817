import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
type Props = {};

export default function MenuBanner({}: Props) {
  return (
    <div className="px-5">
      <Swiper
        className="mt-[13px] aspect-[375/130] bg-white rounded-[15px]"
        spaceBetween={10}
        slidesPerView={1}
        autoplay
        loop
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide className=" bg-amber-500 rounded-[15px]" />
        <SwiperSlide className=" bg-blue-500 rounded-[15px]" />
        <SwiperSlide className=" bg-green-500 rounded-[15px]" />
      </Swiper>
    </div>
  );
}
