import { Outlet } from 'react-router-dom';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { ReactComponent as GlobalIcon } from 'assets/svg/global.svg';
import Select from './Select';

type Props = {};

export default function AdminBeforeLoginLayout({}: Props) {
  return (
    <div className='flex flex-col h-screen px-[20px] md:px-[120px]'>
      <div className='flex items-start justify-between pt-[30px] border-b-[1px] border-gray1'>
        <span>
          <LogoSvg className='w-[60px] h-[16px] md:w-[88px] md:h-[22px]' />
          <div className='mt-[20px] h-[3px] md:h-[7px] bg-main1  rounded-[2px]' />
        </span>
        <span className='flex items-center gap-4'>
          <span
            className='text-main7 text-[13px] font-NEXONFootballGothicBA1 cursor-pointer'
            onClick={() => alert('개발중')}
          >
            Language
          </span>
          <GlobalIcon />
        </span>
      </div>
      <Outlet />
    </div>
  );
}
