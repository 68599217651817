import Button from 'components/style/Button';
import Input from 'components/style/Input';
import Textarea from 'components/style/Textarea';
import { ReactComponent as PhonBackground } from 'assets/svg/phonBackground.svg';
import { getCookie } from 'api/cookie';
import { useQuery } from '@tanstack/react-query';
import { fetchRestaurant, Restaurant } from 'api/admin/restaurants';
import Loader from 'components/common/Loader';
import ErrorComponent from 'components/common/ErrorComponent';

type Props = {};

/**
 * 레스토랑 로고
 * 레스토랑 이름 (string)
 * 결제 통화 단위 (string)
 * 구글 맵 위치 (string)
 * 주소 (string)
 * 원산지 표기 (string)
 * instagram url 주소 (string)
 * 오픈 시간 (string)
 */

export default function Settings({}: Props) {
  const restaurantId = getCookie('restaurantId');
  const { data, error, isLoading } = useQuery<Restaurant>({
    queryKey: ['restaurantDetail'],
    queryFn: () => fetchRestaurant(restaurantId),
    enabled: restaurantId ? true : false,
  });

  // 에러가 발생했을 경우 에러 메시지 출력
  if (error) {
    return <ErrorComponent error={error} resetErrorBoundary={() => {}} />;
  }

  // 로딩 중일 경우 로더 표시
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className='flex w-full'>
      {/* contents */}
      <section className='flex-[2] px-5'>
        <form className='flex flex-col gap-4 w-full'>
          <label className='bg-gray1 rounded-xl h-[100px] w-[150px] flex justify-center items-center cursor-pointer'>
            <input type='file' accept='images/*' hidden />
            <span className='text-gray2 text-[12px] md:text-[14px]'>레스토랑 로고</span>
          </label>

          <Input value={data?.payload.name} placeholder='레스토랑 이름' required type='text' style='w-full' />
          <Input placeholder='결제 통화 단위' required type='text' style='w-full' />
          <Input placeholder='구글 맵 위치 Url' required type='text' style='w-full' />
          <Input placeholder='주소' required type='text' style='w-full' />
          <Textarea placeholder='원산지 표기' style='h-[150px]' />
          <Input placeholder='Instagram Url' required type='text' style='w-full' />
          <Textarea placeholder='오픈시간' style='h-[80px]' />
          <Button text='저장하기' style='bg-black text-white mt-[36px]' onClick={() => alert('개발중')} />
        </form>
      </section>
      {/* preview */}
      <section className='pl-8 pt-4 border-l border-gray1 hidden md:block'>
        <span className='text-gray2'>미리보기</span>
        <div className='mt-6 relative'>
          <div className='absolute z-[-1]'>
            <PhonBackground />
          </div>
          <div className='pt-[63px] pl-[24px] w-[210px] h-[470px] *:text-[12px] flex flex-col'>
            <span>레스토랑</span>
            <span>10,000원</span>
            <span>https://www.naver.com</span>
          </div>
        </div>
      </section>
    </div>
  );
}
