import AuthInput from 'components/style/AuthInput';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import AuthButton from 'components/style/AuthButton';
import { Link } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ERROR_MESSAGE, PASSWORD_REGEX, PASSWORD_REGEX_ERROR } from 'lib/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { setCookie } from 'api/cookie';
import Loader from 'components/common/Loader';
import { useState } from 'react';
import { ERROR_CODES } from 'lib/error';
import OneBtnModal from 'components/common/OneBtnModal';
import useModalStore from 'store/useModalStore';

type Props = {};

interface SignInResponse {
  payload: {
    accessToken: string;
    refreshToken: string;
  };
  error: null;
}

const loginSchema = z.object({
  email: z.string().email({ message: '이메일을 입력해주세요' }),
  password: z.string().trim().regex(PASSWORD_REGEX, PASSWORD_REGEX_ERROR),
});

export default function Login({}: Props) {
  const [alertMsg, setAlertMsg] = useState('');
  const { modals, setStatus } = useModalStore();

  // const { data } = useQuery({
  //   queryKey: ['languages'],
  //   queryFn: () =>
  //     api({
  //       method: 'GET',
  //       url: '/languages',
  //     }),
  // });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
  });
  const { mutate, isPending } = useMutation<SignInResponse, ApiError, ApiProps>({ mutationFn: api });

  const onSubmit = (data: z.infer<typeof loginSchema>) => {
    const { email, password } = data;
    mutate(
      {
        method: 'POST',
        url: '/auth/sign-in',
        data: {
          email,
          password,
        },
      },
      {
        async onSuccess(data, variables, context) {
          await setCookie('accessToken', data.payload.accessToken);
          await setCookie('refreshToken', data.payload.refreshToken);
          window.location.replace('/admin');
        },
        onError(error, variables, context) {
          if (ERROR_CODES[error.error.code]) {
            setAlertMsg(ERROR_CODES[error.error.code]);
          } else {
            setAlertMsg(ERROR_MESSAGE);
          }

          setStatus('oneBtnModal');
        },
      }
    );
  };

  return (
    <div className='flex flex-col justify-center items-center w-full h-full max-w-[335px] m-auto'>
      {modals.oneBtnModal.isOpen && <OneBtnModal title='안내' contents={alertMsg} />}
      {isPending && <Loader />}
      <LogoSvg width={88} height={18} className='mb-[50px] hidden md:block ' />
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-[15px] w-full'>
        <AuthInput
          {...register('email')}
          error={errors.email?.message}
          placeholder='이메일 (아이디)'
          required={true}
          type='text'
        />
        <AuthInput
          {...register('password')}
          error={errors.password?.message}
          placeholder='비밀번호'
          required={true}
          type='password'
        />

        <AuthButton style='mt-[31px] bg-black text-white' text='로그인' type='submit' />
      </form>
      <div className='w-full flex justify-between item-center gap-[30px] mt-[29px]'>
        <Link to={'/admin/signUp'} className='w-full'>
          <AuthButton style='bg-lightGreen1 text-gray4' text='회원가입' type='button' />
        </Link>
        <Link to={'/admin/find/password'} className='w-full'>
          <AuthButton style='bg-gray4 text-white' text='비밀번호 재설정' type='button' />
        </Link>
      </div>
    </div>
  );
}
