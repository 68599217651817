import React from "react";
import { ReactComponent as LanguageSvg } from "assets/svg/global.svg";
import { Link } from "react-router";

type Props = {};

export default function MenuHeader({}: Props) {
  return (
    <div className="w-full py-[16px] px-[20px] flex justify-between items-center">
      <h3 className="font-semibold text-[16px]">로고컴플렉스</h3>
      <span className="flex items-center gap-2">
        <h3 className="font-medium text-[14px] text-main7">한국어 메뉴판</h3>
        <Link to={"/restaurant"}>
          <LanguageSvg width={28} height={28} />
        </Link>
      </span>
    </div>
  );
}
