type Props = {
  style?: string;
  placeholder: string;
  readonly?: boolean;
};

export default function Textarea({ style, placeholder, readonly = false }: Props) {
  return (
    <>
      <textarea
        placeholder={placeholder}
        // type={type}
        readOnly={readonly}
        className={`w-full border-[1px] border-gray3 rounded-[5px] pl-[11px] pt-[11px] text-[12px] md:text-[14px] placeholder:text-[14px] placeholder:text-gray2 ${style}`}
      />
    </>
  );
}
