import { Outlet } from 'react-router';
import SideMenuBar from './SideMenuBar';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { ReactComponent as GlobalIcon } from 'assets/svg/global.svg';
import Select from './Select';
import { useNavigate } from 'react-router';
import { removeCookie } from 'api/cookie';

type Props = {};

export default function AdminAfterLoginLayout({}: Props) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await removeCookie('accessToken');
    await removeCookie('refreshToken');
    await removeCookie('restaurantId');
    navigate('/admin/login');
  };

  return (
    <div className='flex flex-col h-screen px-[20px] md:px-[120px]'>
      <div className='flex items-start justify-between pt-[30px] border-b-[1px] border-gray1'>
        <span>
          <LogoSvg className='w-[60px] h-[16px] md:w-[88px] md:h-[22px]' />
          <div className='mt-[20px] h-[3px] md:h-[7px] bg-main1  rounded-[2px]' />
        </span>
        <span className='flex items-center gap-4'>
          <GlobalIcon className='w-[20px] md:w-[30px]' />
          <Select
            defaultValue={'onnu@onnu.com'}
            list={[
              {
                label: '로그아웃',
                value: 'logout',

                onClick: handleLogout,
              },
            ]}
          />
        </span>
      </div>
      <div className={`flex-1 flex py-[39px] max-w-[1600px] w-full mx-auto md:px-[120px]`}>
        <SideMenuBar />
        <Outlet />
      </div>
    </div>
  );
}
