import Input from 'components/style/Input';
import Textarea from 'components/style/Textarea';
import { ReactComponent as QRCode } from 'assets/svg/QR-Code.svg';

type Props = {};

/**
 * 레스토랑 이름은 셀렉트 박스로 선택
 * QR Code
 * 레스토랑 메뉴판 주소
 * 레스토랑 설정 정보?
 * 고객이 많이 눌러본 메뉴 TOP 5
 * 고객이 많이 하트 누른 TOP 5
 */

export default function Dashboard({}: Props) {
  return (
    <div className='flex gap-14 pl-[32px]  border-red-500 w-full'>
      {/* QR */}
      <section className='flex-1'>
        <div>
          <QRCode width={160} height={160} />
        </div>
      </section>

      {/* QR Code download btn && 레스토랑 메뉴판 주소 */}
      <section className='flex-[5] flex flex-col gap-8'>
        <Input placeholder='QR Code Download btn' type='text' required readOnly />
        <Input placeholder='레스토랑 메뉴판 주소' type='text' required readOnly />
        <Textarea placeholder='이거 뭐였지?..' style='h-[340px]' readonly />
      </section>

      {/* 고객이 많이 눌러본 TOP 5 && 고객이 많이 하트 누른 TOP 5 */}
      <section className='flex-[5] flex flex-col gap-4'>
        <div>
          <label className='text-[14px] font-bold mb-3 inline-block'>인기 조회 메뉴 TOP 5</label>
          <Textarea
            placeholder={'연어회\n모듬회\n매운탕\n새우튀김\n꽃게튀김'}
            style='h-[200px] placeholder:text-gray-700 placeholder:font-semibold'
            readonly
          />
        </div>

        <div>
          <label className='text-[14px] font-bold mb-3 inline-block'>좋아요 많은 메뉴 TOP 5</label>
          <Textarea
            placeholder={'연어회\n모듬회\n매운탕\n새우튀김\n꽃게튀김'}
            style='h-[200px] placeholder:text-gray-700 placeholder:font-semibold'
            readonly
          />
        </div>
      </section>
    </div>
  );
}
