import React from "react";
import useCategory from "store/service/useCategory";
import { categories } from "./MenuCategory";
import { Link } from "react-router";

type Props = {};

const menuList = [
  {
    id: "1",
    name: "오므라이스",
    minPrice: "3000",
    maxPrice: "12000",
    description:
      "맛좋은 오므라이스입니다.맛좋은 오므라이스입니다.맛좋은 오므라이스입니다.맛좋은 오므라이스입니다.맛좋은 오므라이스입니다.맛좋은 오므라이스입니다.",
  },
  {
    id: "2",
    name: "김치볶음밥",
    minPrice: "8000",
    maxPrice: null,
    description: "매콤달콤한 김치볶음밥",
  },
  {
    id: "3",
    name: "돈까스",
    minPrice: "9000",
    maxPrice: null,
    description: "바삭바삭한 돈까스",
  },
  {
    id: "4",
    name: "우동",
    minPrice: "7000",
    maxPrice: null,
    description: "시원한 우동",
  },
  {
    id: "5",
    name: "라면",
    minPrice: "4000",
    maxPrice: null,
    description: "얼큰한 라면",
  },
  {
    id: "6",
    name: "아메리카노",
    minPrice: "3000",
    maxPrice: null,
    description: "향긋한 아메리카노",
  },
  {
    id: "7",
    name: "카페라떼",
    minPrice: "4000",
    maxPrice: null,
    description: "부드러운 카페라떼",
  },
  {
    id: "8",
    name: "맥주",
    minPrice: "5000",
    maxPrice: null,
    description: "시원한 생맥주 500cc",
  },
];

export default function MenuList({}: Props) {
  const { state } = useCategory();
  return (
    <div className="px-5">
      <h3 className="text-base font-semibold mt-[13px] mb-[10px]">
        {categories[state]}
      </h3>

      {menuList.map((menu, index) => (
        <Link
          to={`/restaurant/menu/detail/${index}`}
          key={menu.name}
          className="flex items-center mb-5"
        >
          <div className="bg-gray1 rounded-[15px] aspect-[100/107] min-w-[100px] max-w-[100px] w-full mr-[53px]">
            <span className="" />
          </div>
          <div className="">
            <h2 className="text-base font-bold mb-3">{menu.name}</h2>
            {menu.maxPrice ? (
              <div className="text-base text-main6 font-bold mb-3">
                <span className="leading-[9.42px] bg-yellow2 rounded-[5.5px]">
                  {`${Number(menu.minPrice).toLocaleString()}원`}
                </span>
                <span>{` ~ ${Number(menu.maxPrice).toLocaleString()}원`}</span>
              </div>
            ) : (
              <span className="text-base text-main6 font-bold mb-3 leading-[9.42px] bg-yellow2 rounded-[5.5px]">{`${Number(
                menu.minPrice
              ).toLocaleString()}원`}</span>
            )}

            <p className="line-clamp-2 text-xs font-light">
              {menu.description}
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
}
