import { create } from "zustand";

interface UseCategory {
  state: number;
  setState: (state: number) => void;
}

const useCategory = create<UseCategory>((set) => ({
  state: 0,
  setState: (number) => set(() => ({ state: number })),
}));

export default useCategory;
