import MoreSelect from "components/common/MoreSlect";
import { ReactComponent as CheckDownIcon } from "assets/svg/chevron-down.svg";
import { ReactComponent as MenuIcon } from "assets/svg/menu.svg";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useRaisedShadow } from "./UseRaisedShadow";
import { useRef, useState } from "react";
import DraggableMenu from "./DraggableMenu";
import useModalStore from "store/useModalStore";

interface Props {
  item: string;
}

const menuItemData = ["간장계란밥", "오므라이스", "라면"];

export default function DraggableCategory({ item }: Props) {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuItems, setMenuItems] = useState<string[]>(menuItemData);
  const { setStatus } = useModalStore();
  return (
    <Reorder.Item
      dragControls={dragControls}
      dragListener={false}
      value={item}
      id={item}
      style={{ boxShadow, y }}
    >
      <li
        className={`border border-gray1 rounded-sm py-2 px-1 flex justify-between items-center transition-all duration-200`}
      >
        <span className="flex items-center justify-center">
          <span
            onPointerDown={(e) => dragControls.start(e)}
            className="cursor-move hover:bg-gray1 p-2 rounded-sm"
          >
            <MenuIcon />
          </span>
          <span
            className="cursor-pointer hover:bg-gray1 p-2 rounded-sm"
            onClick={() => {
              if (menuRef.current) {
                menuRef.current.hidden = !menuRef.current.hidden;
              }
            }}
          >
            <CheckDownIcon />
          </span>
          <span className="text-[14px] font-semibold pl-1">{item}</span>
        </span>
        <span>
          <MoreSelect
            list={[
              {
                label: "수정하기",
                name: "수정하기",
                onClick: () => alert("개발중"),
              },
              {
                label: "삭제하기",
                name: "삭제하기",
                onClick: () => alert("개발중"),
              },
            ]}
          />
        </span>
      </li>

      <div ref={menuRef} hidden>
        <Reorder.Group
          dragPropagation={false}
          axis="y"
          onReorder={setMenuItems}
          values={menuItems}
        >
          <ul className="flex flex-col gpa-2 pl-[50px] mt-4 text-[12px]">
            {menuItems.map((menu) => (
              <DraggableMenu key={menu} item={menu} />
            ))}
          </ul>
        </Reorder.Group>

        <div className="mt-4 text-end">
          <button
            onClick={() => setStatus("createMenuModal")}
            className="cursor-pointer text-[16px] font-semibold rounded-md border py-1 px-2 text-main hover:text-main5 hover:transition-colors duration-300 hover:animate-pulse"
          >
            새 메뉴 추가
          </button>
        </div>
      </div>
    </Reorder.Item>
  );
}
