import { forwardRef, InputHTMLAttributes } from 'react';

type Props = {
  style?: string;
  placeholder: string;
  required: boolean;
  error?: string;
};

const AuthInput = forwardRef<HTMLInputElement, Props & InputHTMLAttributes<HTMLInputElement>>(
  ({ style, placeholder, required, error, ...rest }, ref) => {
    return (
      <div className='w-full'>
        <input
          ref={ref}
          {...rest}
          placeholder={placeholder}
          className={`${style} w-full border-[1px] border-gray3 rounded-[5px] pl-[11px] h-[44px] text-[12px] md:text-[14px] placeholder:text-gray2`}
        />
        {error && <span className='text-red-500 text-[10px] md:text-[12px] mt-1 pl-1'>{error}</span>}
      </div>
    );
  }
);

export default AuthInput;
