import axios, { AxiosResponse } from 'axios';
import instance, { BASE_URL } from './interceptor';

export interface ApiProps {
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
  url: string;
  data?: any;
  params?: any;
}

export interface ApiError {
  payload: null;
  error: {
    code: string;
    message: string;
  };
}

export const api = async (apiInfo: ApiProps): Promise<any> => {
  const { method, url, data, params } = apiInfo;
  return await instance({
    method,
    url,
    data,
    params,
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      accept: 'application/json',
    },
    withCredentials: false,
  }).then((res) => res.data);
};

export interface MulterApiPros {
  formData: FormData;
}

// 이미지,파일 업로드
export async function multerApi({ formData }: MulterApiPros): Promise<any> {
  return instance({
    method: 'POST',
    url: '/files',
    data: { file: formData },
    headers: {
      'content-type': 'multipart/form-data; charset=EUC-KR',
      accept: '*/*',
    },
    withCredentials: false,
  }).then((res) => res.data);
}
