import React from 'react';

type Props = {
  style?: string;
  text: string;
  onClick: () => void;
};

export default function Button({ style, text, onClick }: Props) {
  return (
    <button
      onClick={onClick}
      className={`w-full h-[48px] font-bold text-[16px] text-center rounded-[10px] opacity-80 hover:opacity-100 transition-colors duration-200 ${style}`}
    >
      {text}
    </button>
  );
}
