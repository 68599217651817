import AuthInput from 'components/style/AuthInput';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import AuthButton from 'components/style/AuthButton';
import { Link, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { useState } from 'react';
import { ERROR_MESSAGE, PASSWORD_REGEX, PASSWORD_REGEX_ERROR } from 'lib/constants';
import useModalStore from 'store/useModalStore';
import OneBtnModal from 'components/common/OneBtnModal';
import Loader from 'components/common/Loader';
import { ERROR_CODES } from 'lib/error';

type Props = {};

export const checkPasswords = ({ newPassword, confirmPassword }: { newPassword: string; confirmPassword: string }) =>
  newPassword === confirmPassword;

const findPasswordSchema = z
  .object({
    email: z.string().email({ message: '이메일을 입력해주세요' }),
    authCode: z.string().trim().min(1, { message: '인증번호를 입력해주세요' }).optional(),
    oldPassword: z.string().trim().regex(PASSWORD_REGEX, PASSWORD_REGEX_ERROR).optional(),
    newPassword: z.string().trim().regex(PASSWORD_REGEX, PASSWORD_REGEX_ERROR).optional(),
    confirmPassword: z.string().trim().min(1, { message: '비밀번호를 입력해주세요' }).optional(),
  })
  .refine(checkPasswords, {
    message: '비밀번호가 일치하지 않습니다',
    path: ['confirmPassword'],
  });

interface IsCodeVerification {
  send: boolean;
  certification: boolean;
}

interface PasswordResetResponse {
  payload: null;
  error: null;
}

export default function FindPassword({}: Props) {
  const navigate = useNavigate();
  const { modals, setStatus } = useModalStore();
  const [alertMsg, setAlertMsg] = useState('');
  const [isCodeVerification, setIsCodeVerification] = useState<IsCodeVerification>({
    send: false,
    certification: false,
  });

  const { mutate, isPending } = useMutation({ mutationFn: api });
  const { mutate: resetPasswordMutate, isPending: resetPasswordPending } = useMutation<
    PasswordResetResponse,
    ApiError,
    ApiProps
  >({ mutationFn: api });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm<z.infer<typeof findPasswordSchema>>({
    resolver: zodResolver(findPasswordSchema),
  });

  const onSubmit = (data: z.infer<typeof findPasswordSchema>) => {
    const email = getValues('email');
    const authCode = getValues('authCode');
    const oldPassword = getValues('oldPassword');
    const newPassword = getValues('newPassword');
    setIsCodeVerification((state) => ({ ...state, send: true }));
    if (isChangePassword) {
      resetPasswordMutate(
        {
          method: 'PATCH',
          url: '/auth/password/reset',
          data: {
            email,
            authCode,
            oldPassword,
            newPassword,
          },
        },
        {
          onSuccess(data, variables, context) {
            setAlertMsg('비밀번호가 변경되었습니다');
            setStatus('oneBtnModal');
          },
          onError(error, variables, context) {
            if (ERROR_CODES[error.error.code]) {
              setAlertMsg(ERROR_CODES[error.error.code]);
            } else {
              setAlertMsg(ERROR_MESSAGE);
            }

            setStatus('oneBtnModal');
          },
        }
      );
    } else {
      isCodeVerification.send ? checkCode() : onSendCode(email);
    }
  };

  // 인증 요청
  const onSendCode = (email: string) => {
    setIsCodeVerification((state) => ({ ...state, send: true }));
    mutate(
      {
        method: 'POST',
        url: '/auth/code',
        data: {
          email: email,
        },
      },
      {
        onSuccess(data, variables, context) {
          setIsCodeVerification((state) => ({ ...state, send: true }));
        },
        onError(error, variables, context) {
          setAlertMsg(ERROR_MESSAGE);
        },
      }
    );
  };

  // 인증 확인
  const checkCode = () => {
    const email = getValues('email');
    const authCode = getValues('authCode');

    mutate(
      {
        method: 'POST',
        url: '/auth/code/verification',
        data: {
          email,
          authCode,
        },
      },
      {
        onSuccess(data, variables, context) {
          setIsCodeVerification({
            send: true,
            certification: true,
          });
        },
        onError(error, variables, context) {
          setError('authCode', {
            type: 'custom',
            message: '인증번호가 일치하지 않습니다',
          });
        },
      }
    );
  };

  const isChangePassword = isCodeVerification.send && isCodeVerification.certification;
  return (
    <div className='flex flex-col justify-center items-center gap-[50px] w-full h-full'>
      {modals.oneBtnModal.isOpen && (
        <OneBtnModal
          title='안내'
          contents={alertMsg}
          onClick={() => {
            if (alertMsg === '비밀번호가 변경되었습니다') {
              navigate('/admin/login');
            }
          }}
        />
      )}
      {(isPending || resetPasswordPending) && <Loader />}
      <LogoSvg width={88} height={18} className='hidden md:block' />
      <form
        onSubmit={handleSubmit(onSubmit, (err) => console.log(err))}
        className='flex flex-col max-w-[335px] w-full gap-[15px]'
      >
        <AuthInput
          {...register('email')}
          error={errors.email?.message}
          placeholder='이메일 (아이디)'
          required={true}
          type='text'
          hidden={isChangePassword}
        />
        {isCodeVerification.send ? (
          <AuthInput
            {...register('authCode')}
            error={errors.authCode?.message}
            placeholder='인증번호'
            required={true}
            type='text'
            hidden={isChangePassword}
          />
        ) : null}

        {isChangePassword ? (
          <>
            <AuthInput
              {...register('oldPassword')}
              error={errors.oldPassword?.message}
              placeholder='기존 비밀번호'
              required={true}
              type='password'
            />
            <AuthInput
              {...register('newPassword')}
              error={errors.newPassword?.message}
              placeholder='새 비밀번호'
              required={true}
              type='password'
            />
            <AuthInput
              {...register('confirmPassword')}
              error={errors.confirmPassword?.message}
              placeholder='새 비밀번호 확인'
              required={true}
              type='password'
            />
          </>
        ) : null}
        <AuthButton
          style='mt-[31px] bg-black text-white'
          text={isChangePassword ? '비밀번호 변경' : isCodeVerification.send ? '인증완료' : '인증요청'}
          type='submit'
        />

        <Link to={'/admin/login'}>
          <AuthButton style='bg-gray1 text-gray4' text='로그인' type='button' />
        </Link>
      </form>
    </div>
  );
}
