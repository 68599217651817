import PcBanner from "assets/images/landing-banner1.png";
import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import { ReactComponent as LanguageIcon } from "assets/svg/global.svg";
import { ReactComponent as Icon1 } from "assets/svg/landing/icon1.svg";
import { ReactComponent as Icon2 } from "assets/svg/landing/icon2.svg";
import { ReactComponent as Icon3 } from "assets/svg/landing/icon3.svg";
import { ReactComponent as Icon4 } from "assets/svg/landing/icon4.svg";
import { ReactComponent as Icon5 } from "assets/svg/landing/icon5.svg";
import { ReactComponent as Icon6 } from "assets/svg/landing/icon6.svg";
import { ReactComponent as Icon7 } from "assets/svg/landing/icon7.svg";
import { ReactComponent as Icon8 } from "assets/svg/landing/icon8.svg";
import { ReactComponent as Icon9 } from "assets/svg/landing/icon9.svg";
import { ReactComponent as Icon10 } from "assets/svg/landing/icon10.svg";
import { ReactComponent as Icon11 } from "assets/svg/landing/icon11.svg";
import { ReactComponent as Arrow } from "assets/svg/landing/right-arrow.svg";
import { ReactComponent as RightArrow } from "assets/svg/landing/landing-right-arrow.svg";
import { ReactComponent as NaverIcon } from "assets/svg/landing/naver-icon.svg";
import { ReactComponent as KakaoIcon } from "assets/svg/landing/kakao-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/svg/landing/instagram-icon.svg";
import { Link } from "react-router";

type Props = {};

const serviceData = [
  {
    text: `AI 번역 기술로\n10개 이상의 언어 지원`,
    icon: Icon1,
  },
  {
    text: `알레르기 정보\n자동 필터링 기능`,
    icon: Icon2,
  },
  {
    text: `QR 코드 스캔으로\n메뉴 번역 및 정보 제공`,
    icon: Icon3,
  },
];

const functionData = [
  {
    text: `QR 스캔`,
    icon: Icon8,
  },
  {
    text: `언어 선택`,
    icon: Icon9,
  },
  {
    text: `특수 식단\n필터 적용`,
    icon: Icon10,
  },
  {
    text: `메뉴 확인`,
    icon: Icon11,
  },
];

const filtering = [
  {
    title: "AI 번역 기능",
    contents: `10 개 이상의 언어를\n지원하여 실시간 번역 제공`,
  },
  {
    title: "특수 식단 필터링",
    contents: `10 개 이상의 언어를\n지원하여 실시간 번역 제공`,
  },
  {
    title: "QR Code 접근성",
    contents: `10 개 이상의 언어를\n지원하여 실시간 번역 제공`,
  },
  {
    title: "메뉴 확인",
    contents: `10 개 이상의 언어를\n지원하여 실시간 번역 제공`,
  },
];

export default function Landing({}: Props) {
  return (
    <div>
      {/* header */}
      <div className="flex justify-between items-center h-[135px] px-8">
        <LogoIcon width={230} height={230} />
        <div className="flex items-center">
          <LanguageIcon width={58} height={58} />
          <Link
            to={"admin/login"}
            className="text-center w-[189px] h-[84px] ml-[41px] mr-[24px] border-2 border-main7 rounded-[9px] text-[32px] font-semibold text-main7 py-[16px]"
          >
            Login in
          </Link>
          <Link
            to={"admin/signUp"}
            className="text-center w-[189px] h-[84px] gradient-button rounded-[9px] text-[32px] font-semibold text-white py-[17px]"
          >
            Sign up
          </Link>
        </div>
      </div>
      {/* section 1 */}
      <section>
        <img src={PcBanner} alt="banner" />
      </section>

      {/* section 2 */}
      <section
        className="w-full flex flex-col items-center justify-center pt-[163px] pb-[130px] font-NEXONFootballGothicBA1"
        style={{
          background:
            "linear-gradient(to bottom,rgb(251, 251, 255),rgb(224, 222, 254),rgb(251, 251, 255))",
        }}
      >
        <span className="landing-text">외국인을 위한 스마트한 메뉴 서비스</span>
        <div className="relative flex items-center justify-center gap-[35px]">
          {serviceData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center w-[376px] aspect-square shadow-2xl bg-white rounded-[20px] mt-[98px]"
            >
              <item.icon width={183} height={183} />
              <span className="mt-[20px] text-[24px] leading-[35px] font-bold whitespace-pre-line text-center">
                {item.text}
              </span>
            </div>
          ))}
          <Icon4 className="absolute -bottom-[296px] -right-[84px]" />
          <Icon5 className="absolute -bottom-[73px] -right-[236px]" />
          <Icon6 className="absolute -bottom-[230px] right-[210px]" />
        </div>

        <div className="pt-[485px]">
          <div className="leading-[64px] text-center">
            <span className="landing-text">{`외국인 중 70% 이상이 메뉴를`}</span>
          </div>
          <div className="leading-[64px] text-center">
            <span className="landing-text">{`이해하지 못해 불편함을 겪고 있습니다.`}</span>
          </div>
        </div>

        <div className="mt-[100px]">
          <Icon7 />
        </div>
      </section>

      {/* section 3 */}
      <section
        className="h-[1700px] w-full flex flex-col items-center pt-[163px] pb-[130px] font-NEXONFootballGothicBA1"
        style={{
          background:
            "linear-gradient(to bottom, rgba(0, 228, 166, 0.2) 0%, rgba(43, 233, 181, 0.2) 17%, #FFFFFF 100%)",
        }}
      >
        <span className="landing-text">
          더 이상 메뉴판 앞에서 망설이게 둘 건가요?
        </span>

        <span className="text-[36px] font-bold whitespace-pre-wrap text-center mt-[113px]">
          {`별도의 앱 설치 없이\n`}
          <span className="leading-[21px] bg-yellow2 rounded-full text-main8">
            {`웹 기반으로`}
          </span>
          {` 빠르게 접근 가능`}
        </span>

        <div className="flex mt-[93px]">
          {functionData.map((item, index) => (
            <div key={index} className="flex items-center">
              <span className="bg-white shadow-2xl w-[220px] aspect-[220/240] rounded-[20px] flex flex-col items-center justify-center whitespace-pre-wrap">
                <item.icon width={100} height={100} />
                <span className="text-center mt-3 font-extrabold text-[28px]">
                  {item.text}
                </span>
              </span>
              <span
                className="px-[27px]"
                hidden={functionData.length - 1 === index}
              >
                <RightArrow />
              </span>
            </div>
          ))}
        </div>

        <span className="text-[36px] font-bold whitespace-pre-wrap text-center mt-[188px]">
          {`간편하게 QR 찍고 \n`}
          <span className="leading-[21px] bg-yellow2 rounded-full text-main8">
            필터링 메뉴판을
          </span>
          {` 사용해보세요!`}
        </span>

        <div className="relative flex items-center justify-center gap-[73px] mt-[109px]">
          {/* 작은 원 가운데 선 */}
          <div className="border-t-4 border-gray6 border-dashed absolute top-[53%] w-[80%]" />

          {filtering.map((item, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center"
            >
              {/* circle */}
              <div className="circle">
                <div className="triangle"></div>
                <span className="number">{index + 1}</span>
              </div>
              {/* title */}
              <span className="text-[25px] font-bold mt-[39px]">
                {item.title}
              </span>
              {/* mini circle */}
              <span
                className={`w-6 h-6 rounded-full mt-[37px] z-10 ${
                  index === 0 || index === 3 ? "bg-main8" : "bg-gray6"
                }`}
              />

              <div className="mt-[24px] w-[240px] h-[142px] px-[20px] py-[37px] border-t-2 border-main8 bg-[rgb(243,254,251)]">
                <span className="text-[20px] font-bold whitespace-pre-wrap text-center">
                  {item.contents}
                </span>
              </div>
            </div>
          ))}
        </div>

        <Link
          to={"/admin/signUp"}
          className="flex items-center justify-center relative mt-[67px] py-[20px] w-[300px] font-semibold text-[22px] bg-main6 rounded-[50px] text-white"
        >
          가입하기
          <Arrow className="absolute right-[23px]" />
        </Link>
      </section>

      {/* footer */}
      <section className="pt-[106px] pb-[230px] mt-[104px] w-[1200px] border-t-2 border-gray6 m-auto">
        <div className="flex items-center">
          <div className="mr-[200px]">
            <div>
              <LogoIcon width={120} height={25} />
            </div>
            <span className="text-[15px] font-normal mt-5 bg-yellow2 leading-[10px] rounded-[20px]">
              글로벌 메뉴판의 새로운 기준, 온뉴온
            </span>
          </div>
          <div className="flex items-center gap-5">
            <button
              className="text-[16px] font-NEXONFootballGothicBA1"
              onClick={() => alert("개발중")}
            >
              이용약관
            </button>
            <div className="h-2 w-[2px] rounded-2xl bg-gray6" />
            <button
              className="text-[16px] font-NEXONFootballGothicBA1"
              onClick={() => alert("개발중")}
            >
              개인정보처리방침
            </button>
          </div>

          <div className="ml-[200px] flex items-center gap-[17px]">
            <Link to={"https://blog.naver.com/onbooth_"} target="_blank">
              <KakaoIcon />
            </Link>
            <Link to={"https://blog.naver.com/onbooth_"} target="_blank">
              <NaverIcon />
            </Link>
            <Link to={"https://instagram.com/onnuon_co"} target="_blank">
              <InstagramIcon />
            </Link>
          </div>
        </div>
        <div className="mt-5">
          <a
            href="tel:15888932"
            className="text-[15px] font-NEXONFootballGothicBA1"
          >
            고객센터 : 1588 - 8932
          </a>
        </div>
        <div>
          <div className="flex gap-[144px] text-[15px] leading-[160%] font-thin whitespace-pre-wrap mt-3">
            <p>
              {`주식회사 온뉴온 대표 : 박성철\n사업자번호 : 000-00-00000\n통신판매업 신고번호 2024-서울삼성-0000\n개인정보책임관리자 : 박성철`}
            </p>
            <p>
              {`이메일 : info@onnuon.com\n주소 : 서울시 강남구 테헤란로 79길 6\n운영시간 평일 10:00 ~ 18:00\n(점심시간 12:00~ 13:00) 주말, 공휴일 휴무`}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
