import React from "react";
import { ReactComponent as LogoSvg } from "assets/svg/logo.svg";
import { ReactComponent as LanguageSvg } from "assets/svg/global.svg";
import { Link } from "react-router";
type Props = {};

const buttonList = ["한국어 메뉴판", "영어 메뉴판"];

export default function RestaurantMenuList({}: Props) {
  return (
    <div className="h-screen relative pt-[56px] flex flex-col items-center px-[40px]">
      <span className="absolute top-[22px] right-[26px]">
        <LanguageSvg width={28} hanging={28} />
      </span>
      <span>
        <LogoSvg width={158} hanging={56} />
      </span>
      <h2 className="text-[15px] mt-[42px] font-bold">lococomplex</h2>

      <div className="w-full h-full flex flex-col items-center justify-center gap-[16px] ">
        {buttonList.map((button, index) => (
          <Link
            to={`/restaurant/menu/${index}`}
            key={index}
            className="w-full rounded-[10px] bg-main7 py-[15px] text-center font-bold text-[15px] text-white"
          >
            {button}
          </Link>
        ))}
        <Link
          to={`/restaurant`}
          className="w-full rounded-[10px] bg-gray1 py-[15px] text-center font-bold text-[15px] text-gray2"
        >
          돌아가기
        </Link>
      </div>
    </div>
  );
}
