import { api } from 'api';

export interface Restaurants {
  payload: [
    {
      pk: string;
      sk: string;
      id: string;
      name: string;
      phone: string;
      managerPhone: string;
    }
  ];
  error: null;
}

// 레스토랑 리스트 조회
export const fetchRestaurants = () => {
  return api({
    method: 'GET',
    url: 'restaurants',
  });
};

export interface Restaurant {
  payload: {
    pk: 'members#paulayground@gmail.com';
    sk: 'restaurants#CZaTL0puBOnPnFrhy6aU';
    id: 'CZaTL0puBOnPnFrhy6aU';
    name: '청춘어랑';
    phone: '01012341234';
    managerPhone: '01012341234';
  };
  error: null;
}

// 레스토랑 조회
export const fetchRestaurant = (id: string) => {
  return api({
    method: 'GET',
    url: `restaurants/${id}`,
  });
};
