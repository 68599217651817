import useBodyOverflow from 'hooks/useBodyOverflow';
import React, { useEffect, useRef } from 'react';
import useModalStore from 'store/useModalStore';

type Props = {
  contents: string;
  title: string;
  onClick?: () => void;
};

export default function OneBtnModal({ contents, title, onClick }: Props) {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const { setStatus } = useModalStore();
  const handleClick = () => setStatus('oneBtnModal');

  return (
    <div className='modal-bg' ref={bgRef} onClick={handleClick}>
      <div
        className='flex flex-col justify-between items-center shadow-xl w-[322px] h-[314px] rounded-[25px] bg-white overflow-hidden'
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className='mt-[30px] text-[14px] text-gray2'>{title}</h2>
        <span className='text-[15px] whitespace-pre-wrap text-center leading-8'>{contents}</span>
        <div className='flex justify-between items-center w-full h-[57px]'>
          <button
            className='w-full h-full bg-main6 text-white'
            onClick={() => {
              if (onClick) {
                onClick();
              }

              handleClick();
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}
