import { ChangeEvent, forwardRef, InputHTMLAttributes } from 'react';

type Props = {
  style?: string;
  error?: string;
};

const Input = forwardRef<HTMLInputElement, Props & InputHTMLAttributes<HTMLInputElement>>(
  ({ style, error, ...rest }, ref) => {
    return (
      <input
        ref={ref}
        {...rest}
        className={`${style} w-full border-[1px] border-gray3 rounded-[5px] px-[11px] h-[40px] md:h-[44px] text-[12px] md:text-[14px] placeholder:text-gray2`}
        autoComplete='off'
      />
    );
  }
);

export default Input;
