import React from "react";
import { Outlet } from "react-router";

type Props = {};

export default function RestaurantLayout({}: Props) {
  return (
    <div className="max-w-[600px] m-auto ">
      <Outlet />
    </div>
  );
}
