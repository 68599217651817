import { useQuery } from '@tanstack/react-query';
import { fetchRestaurants, Restaurants } from 'api/admin/restaurants';
import { setCookie } from 'api/cookie';
import Loader from 'components/common/Loader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type Props = {};

export default function Home({}: Props) {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<Restaurants>({
    queryKey: ['restaurantList'],
    queryFn: fetchRestaurants,
  });

  useEffect(() => {
    if (data) {
      setCookie('restaurantId', data?.payload[0].id);
      navigate(`/admin/dashboard/${data?.payload[0].id}`);
    }
  }, [data]);

  return <Loader />;
}
