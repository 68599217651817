import BannerDetailModal from 'components/banner/BannerDetailModal';
import Pagination from 'components/common/Pagination';
import Toggle from 'components/common/Toggle';
import TwoBtnModal from 'components/common/TwoBtnModal';
import { useState } from 'react';
import { Link } from 'react-router';
import useModalStore from 'store/useModalStore';

type Props = {};

const List = [
  {
    bannerImg: '#',
    bannerUrl: 'https://www.naver.com',
    isDisplay: true,
  },
];

export default function Banners({}: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // 페이지당 보여줄 아이템 수
  const { modals, setStatus } = useModalStore();

  // 현재 페이지에 해당하는 아이템들만 계산 (temp)
  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = Array(100).fill(List[0]).slice(startIndex, endIndex);
    console.log('result', result);
    return result;
  };

  return (
    <div className='w-full'>
      {modals.twoBtnModal.isOpen && (
        <TwoBtnModal
          contents='정말로 삭제하시겠습니까?'
          leftFn={() => alert('개발중')}
          leftText='삭제'
          rightFn={() => setStatus('twoBtnModal')}
          rightText='취소'
          title='배너 삭제'
        />
      )}
      {modals.bannerDetailModal.isOpen && <BannerDetailModal />}
      <div className='flex justify-end items-center pt-4 py-8'>
        <button
          onClick={() => setStatus('bannerDetailModal')}
          className='bg-main3 text-white rounded-xl shadow-lg py-2 px-4'
        >
          배너 생성
        </button>
      </div>
      {/* title */}
      <li className='flex justify-between items-center pb-[25px] font-bold *:text-center'>
        <span className='flex-[0.6]'>번호</span>
        <span className='flex-[2]'>배너 이미지</span>
        <span className='flex-[1.3]'>배너 URL</span>
        <span className='flex-1'>배너 활성화</span>
        <span className='flex-1'></span>
      </li>

      {/* contents */}
      <ul className='flex flex-col gap-6'>
        {getCurrentItems().map((list, index) => (
          <li key={index} className='flex justify-between items-center *:text-center text-gray2'>
            <span className='flex-[0.6]'>{(currentPage - 1) * itemsPerPage + index + 1}</span>
            <span className='flex-[2] flex justify-center items-center'>
              <div className='bg-gray-300 w-[90%] h-14 rounded-lg'></div>
            </span>
            <Link
              className='flex-[1.3] hover:text-gray-950 transition-colors duration-300'
              to={list.bannerUrl}
              target='_blank'
            >
              {list.bannerUrl}
            </Link>
            <span className='flex-1'>
              <Toggle />
            </span>
            <span className='flex-1 flex justify-around gap-1 *:text-gray2 transition-colors duration-300'>
              <button className='hover:text-gray-950' onClick={() => setStatus('bannerDetailModal')}>
                상세 보기
              </button>
              <button className='hover:text-gray-950' onClick={() => setStatus('twoBtnModal')}>
                삭제
              </button>
            </span>
          </li>
        ))}
      </ul>

      <div className='mt-[50px]'>
        <Pagination
          totalItems={150}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
}
