import { useState } from "react";
import useCategory from "store/service/useCategory";

type Props = {};

export const categories = [
  "전체",
  "메인",
  "사이드",
  "음료",
  "기타",
  "주류",
  "음료",
  "기타",
  "주류",
  "음료",
  "기타",
  "주류",
  "음료",
  "기타",
  "주류",
];

export default function MenuCategory({}: Props) {
  const { state, setState } = useCategory();
  return (
    <div className="mt-[18px]">
      <div className="scroll w-full px-5 flex items-center overflow-x-scroll pb-[18px]">
        {categories.map((category, categoryIndex) => (
          <div className="relative pr-5">
            <div className="absolute top-[2px] h-[1px] bg-gray1 w-full" />
            <span className="flex flex-col items-center justify-center gap-[18px]">
              <div
                className={`w-full h-[6px] rounded-[2px] z-10 ${
                  state !== categoryIndex ? "bg-none" : "bg-main1"
                } `}
              />
              <span
                key={category}
                onClick={(e) => setState(categoryIndex)}
                className={`text-[16px] text-nowrap ${
                  state === categoryIndex
                    ? "text-main7 font-bold"
                    : "text-gray2 font-normal "
                } `}
              >
                {category}
              </span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
