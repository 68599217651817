import CustomButton from 'components/style/Button';
import Select from 'components/common/Select';
import { ReactComponent as PhonBackground } from 'assets/svg/phonBackground.svg';

const language = [
  {
    label: '한국어',
    value: 'korea',
  },
  {
    label: '영어',
    value: 'english',
  },
  {
    label: '일본어',
    value: 'japanese',
  },
  {
    label: '중국어',
    value: 'chinese',
  },
];

type Props = {};

export default function Unlisted({}: Props) {
  return (
    <>
      {/* contents */}
      <section className='flex flex-col pt-[40px] flex-[2] px-[60px]'>
        <h2 className='text-[16px] font-bold mb-[40px]'>메뉴판 명</h2>
        <div className='flex gap-7'>
          <div className='flex flex-col items-center justify-center border w-full aspect-video rounded-md'>
            <span className='text-[14px] text-gray2 font-semibold'>수동작성</span>
            <Select list={language} placeholder='언어 선택' style={'text-[14px] text-gray2 font-semibold mt-2'} />
          </div>

          <label className='flex items-center justify-center border w-full aspect-video rounded-md cursor-pointer'>
            <span className='text-[14px] text-gray2 font-semibold'>엑셀 업로드</span>
            <input hidden type={'file'} accept='.xlsx' />
          </label>
        </div>

        <CustomButton text='메뉴판 생성' style='bg-black text-white mt-[60px]' onClick={() => alert('개발중')} />
      </section>
      {/* preview */}
      <section className='pl-8 pt-4 border-l border-gray1'>
        <span className='text-gray2'>미리보기</span>
        <div className='mt-6 relative'>
          <div className='absolute z-[-1]'>
            <PhonBackground />
          </div>
          <div className='pt-[63px] pl-[24px] w-[210px] h-[470px] *:text-[12px] flex flex-col'></div>
        </div>
      </section>
    </>
  );
}
