import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from './Select';
import { useQuery } from '@tanstack/react-query';
import { fetchRestaurants, Restaurants } from 'api/admin/restaurants';

type Props = {};

const menuList = [
  { idx: 'korea', name: '한국어' },
  { idx: 'english', name: '영어' },
];

export default function SideMenuBar({}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [restaurantList, setRestaurantList] = useState<{ value: string; label: string }[]>([]);

  const { data, isLoading } = useQuery<Restaurants>({
    queryKey: ['restaurantList'],
    queryFn: fetchRestaurants,
  });

  useEffect(() => {
    if (data) {
      setRestaurantList(
        data.payload.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [data]);

  return (
    <div className='border-r border-gray1 h-full w-[170px] pr-4 hidden md:block'>
      <ul className='flex flex-col gap-[30px] text-gray2 text-[18px] font-bold '>
        <div
          className={`${location?.pathname.includes('dashboard') && 'text-main1 '}`}
          // to={"/admin/dashboard"}
        >
          <Select
            clicked={location?.pathname.includes('dashboard')}
            list={restaurantList}
            defaultValue={restaurantList[0]?.label}
            onChange={() => navigate('/admin/dashboard')}
            style='hover:transition-colors hover:duration-200 hover:text-main2 *:hover:stroke-main2 *:hover:transition-colors *:hover:duration-200'
          />
          {/* 레스토랑 이름 */}
        </div>
        <div>
          <Link
            className={`${
              location?.pathname.includes('menu') && 'text-main1'
            } hover:transition-colors hover:duration-200 hover:text-main2`}
            to={'/admin/menu'}
          >
            메뉴
          </Link>
          <ul className='flex flex-col gap-2 pl-3 mt-2'>
            {menuList.map((menu, index) => (
              <Link
                to={`/admin/menu/${menu.idx}`}
                key={menu.idx}
                className={`${
                  location?.pathname.includes(menu.idx) && 'text-main1'
                } text-[14px] hover:text-main2 hover:transition-colors hover:duration-200 cursor-pointer`}
              >
                {menu.name}
              </Link>
            ))}
          </ul>
        </div>

        <Link
          className={`${
            location?.pathname.includes('settings') && 'text-main1'
          } hover:transition-colors hover:duration-200 hover:text-main2
          `}
          to={'/admin/settings'}
        >
          설정
        </Link>
        <Link
          className={`${
            location?.pathname.includes('banners') && 'text-main1'
          } hover:transition-colors hover:duration-200 hover:text-main2`}
          to={'/admin/banners'}
        >
          배너 관리
        </Link>
        <Link
          className={`${
            location?.pathname.includes('partners') && 'text-main1'
          } hover:transition-colors hover:duration-200 hover:text-main2`}
          to={'/admin/partners'}
        >
          입점사 관리
        </Link>
      </ul>
    </div>
  );
}
