import MenuHeader from "components/service/MenuHeader";
import React from "react";
import { ReactComponent as CheckIcon } from "assets/svg/check-icon.svg";
import { ReactComponent as BackIcon } from "assets/svg/back_icon.svg";
import { Link, useNavigate } from "react-router";

type Props = {};

const filter = [
  "Halal",
  "Vegun",
  "Vegun",
  "Vegun",
  "Vegun",
  "Vegun",
  "Vegun",
  "Vegun",
  "Vegun",
];

export default function RestaurantMenuDetail({}: Props) {
  const navigate = useNavigate();
  return (
    <div className="pb-[50px]">
      <MenuHeader />
      <div className="relative bg-gray1 w-full aspect-[415/324]">
        <span
          onClick={() => navigate(-1)}
          className="absolute top-[22.51px] left-[18.83px]"
        >
          <BackIcon />
        </span>
      </div>

      <div className="px-[30px]">
        <div className="flex items-center gap-3 flex-wrap py-[30px] border-b border-gray1">
          {filter.map((item, index) => (
            <span className="text-xs" key={item}>
              {item}
            </span>
          ))}
        </div>
      </div>

      <div className="px-6">
        <div className="flex items-center gap-[10px] mt-6 ">
          <h2 className="font-bold text-[18px]">Vongole pasta</h2>

          <CheckIcon />
        </div>
        <span className="mt-3 text-main6 text-xs leading-[9.42px] font-bold bg-yellow2 rounded-[5.5px]">
          150,000원
        </span>
        <p className="mt-3 text-xs leading-4 text-center">
          Experience the flavors of the sea with our Vongole Mare. Fresh clams,
          cherry tomatoes, and a hint of chili flakes tossed with spaghetti in a
          savory garlic and white wine sauce.
        </p>

        <p className="mt-8 text-xs leading-4 font-semibold">
          추가옵션 1 : 3,000원
        </p>
        <p className="mt-2 text-xs leading-4 font-semibold">
          추가옵션 2 : 5,000원
        </p>
        <p className="mt-2 text-xs leading-4 font-semibold">
          추가옵션 3 : 6,000원
        </p>
      </div>
    </div>
  );
}
