import { ReactComponent as FilterIcon } from "assets/svg/filter-square.svg";
import SettingModal from "./SettingModal";
import useModalStore from "store/useModalStore";
import { motion, AnimatePresence } from "framer-motion";
type Props = {};

export default function MenuSettings({}: Props) {
  const { setStatus } = useModalStore();
  return (
    <>
      {<SettingModal />}

      <div className="mx-[20px] pb-5 border-b border-b-gray1">
        <div
          className="w-full mt-[12px] px-[29px] flex items-center justify-between"
          onClick={() => setStatus("menuSettingModal")}
        >
          <FilterIcon />

          <span className="w-full text-center">맞춤 설정하세요.</span>
        </div>
      </div>
    </>
  );
}
