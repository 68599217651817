// ... existing imports ...

import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "assets/svg/arrow-y.svg";
import { ReactComponent as MoreIcon } from "assets/svg/more-vertical.svg";

type Props = {
  list: { name: string; label: string; onClick?: () => void }[];
  onChange?: (value: string) => void;
  style?: string;
};

export default function MoreSelect({ list, onChange, style }: Props) {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState<string>(list[0].label);
  const containerRef = useRef<HTMLDivElement>(null);

  // 선택 핸들러
  const handleSelect = useCallback(
    (item: { name: string; label: string }) => {
      setSelected(item.name);
      setShowList(false);
      onChange?.(item.name);
    },
    [onChange]
  );

  // 외부 클릭 감지
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={containerRef} className="relative">
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded={showList}
        className={`flex items-center gap-2 w-full text-left rounded-md ${style}`}
        onClick={() => setShowList((prev) => !prev)}
      >
        <MoreIcon />
      </button>

      {showList && (
        <ul
          role="listbox"
          className="absolute w-full min-w-[80px] mt-1 border border-black rounded-md shadow-lg bg-white z-10 overflow-hidden"
        >
          {list.map((item, index) => (
            <li
              key={item.name}
              role="option"
              aria-selected={selected === item.name}
              className={`px-4 pt-2 cursor-pointer hover:bg-gray-100 font-bold text-[14px] text-nowrap
                ${selected === item.name ? "bg-gray-50" : ""}`}
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                }
                handleSelect(item);
              }}
            >
              {item.label}
              <div className="border-b border-gray1 pb-2" />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
