import { create } from "zustand";

interface ModalState {
  isOpen: boolean;
}

interface UseModalStore<T extends string> {
  modals: Record<T, ModalState>;
  setStatus: (modalName: T) => void;
}

type ModalNames =
  | "oneBtnModal"
  | "twoBtnModal"
  | "createCategoryModal"
  | "createMenuModal"
  | "createTagModal"
  | "bannerDetailModal"
  | "menuSettingModal";

const useModalStore = create<UseModalStore<ModalNames>>((set) => ({
  modals: {
    oneBtnModal: { isOpen: false },
    twoBtnModal: { isOpen: false },
    createCategoryModal: { isOpen: false },
    createMenuModal: { isOpen: false },
    createTagModal: { isOpen: false },
    bannerDetailModal: { isOpen: false },
    menuSettingModal: { isOpen: false },
  },
  setStatus: (modalName) =>
    set((state) => ({
      modals: {
        ...state.modals,
        [modalName]: { isOpen: !state.modals[modalName].isOpen },
      },
    })),
}));

export default useModalStore;
