import { ReactComponent as PhonBackground } from 'assets/svg/phonBackground.svg';
import { ReactComponent as GlobalIcon } from 'assets/svg/global.svg';
import { Link } from 'react-router';
import MoreSelect from 'components/common/MoreSlect';

type Props = {};

const list = ['한국어', '영어'];

export default function MenuList({}: Props) {
  return (
    <>
      {/* contents */}
      <div className='flex-[2] md:pt-[40px] md:px-[45px]'>
        <div className='flex items-center justify-end pb-10'>
          <Link
            to={'/admin/menu/create'}
            className='text-[16px] font-semibold rounded-md border py-1 px-2 text-main hover:text-main1 hover:transition-colors duration-300 hover:animate-pulse'
          >
            새 메뉴판 만들기
          </Link>
        </div>
        <section className='flex flex-col '>
          <ul className='flex flex-col gap-2'>
            {list.map((item, index) => (
              <li key={item} className='flex justify-between items-center'>
                <span className='text-[16px] font-semibold'>{`${item} 메뉴판`}</span>
                <span className='flex items-center justify-start gap-2'>
                  <GlobalIcon />
                  <span className='w-[50px]'>{item}</span>
                  <span className='cursor-pointer'>
                    <MoreSelect
                      list={[
                        {
                          label: '저장하기',
                          name: '저장하기',
                          onClick: () => alert('개발중'),
                        },
                        {
                          label: '삭제하기',
                          name: '삭제하기',
                          onClick: () => alert('개발중'),
                        },
                      ]}
                    />
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </section>
      </div>
      {/* preview */}
      <section className='pl-8 pt-4 border-l border-gray1 hidden md:block'>
        <span className='text-gray2'>미리보기</span>
        <div className='mt-6 relative'>
          <div className='absolute z-[-1]'>
            <PhonBackground />
          </div>
          <div className='pt-[63px] pl-[24px] w-[210px] h-[470px] *:text-[12px] flex flex-col'></div>
        </div>
      </section>
    </>
  );
}
