import Toggle from 'components/common/Toggle';
import CustomButton from 'components/style/Button';
import CustomInput from 'components/style/Input';
import useMulter from 'hooks/useMulter';
import { useRef } from 'react';
import useModalStore from 'store/useModalStore';

type Props = {};

export default function BannerDetailModal({}: Props) {
  const bgRef = useRef<HTMLDivElement>(null);
  const { setStatus } = useModalStore();
  const handleClick = () => setStatus('bannerDetailModal');

  const { multerMutate, saveFile } = useMulter();

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = await saveFile(e, 'single');

    if (files) {
      multerMutate(
        { formData: files.formData },
        {
          onSuccess(data, variables, context) {
            console.log('성공');
            console.log(data);
          },
          onError(error, variables, context) {
            console.log('실패');
            console.log(error);
          },
        }
      );
    }
  };

  return (
    <div className='modal-bg' ref={bgRef} onClick={handleClick}>
      <div
        className='relative shadow-xl rounded-lg bg-white py-4 px-4 border border-gray-100 max-w-[600px] w-full'
        onClick={(e) => e.stopPropagation()}
      >
        <h1 className='font-bold text-[18px]'>배너 상세</h1>
        <div className='py-4 px-4'>
          <div>
            <div className='mt-8'>
              <h2 className='font-semibold text-[16px]'>배너 이미지</h2>

              <label className='mt-4 flex justify-center items-center border border-gray1 rounded-lg w-full h-[200px] text-gray2 font-medium cursor-pointer'>
                <span>배너 이미지 업로드</span>
                <input type='file' accept='images/*' hidden onChange={handleFileInput} />
              </label>
            </div>

            <div className='mt-8'>
              <h2 className='font-semibold text-[16px]'>배너 URL</h2>
              <CustomInput placeholder='https://www.naver.com' required={false} type='text' style='mt-4' />
            </div>

            <div className='mt-8'>
              <h2 className='font-semibold text-[16px]'>배너 활성화</h2>
              <div className='flex justify-start items-center mt-4'>
                <Toggle />
              </div>
            </div>

            <div className='flex justify-center items-center mt-10 gap-4'>
              <CustomButton style='bg-main1 text-white' text='저장' onClick={() => alert('개발중')} />
              <CustomButton style='bg-gray1 text-white' text='닫기' onClick={handleClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
