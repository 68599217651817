import MoreSelect from "components/common/MoreSlect";
import { ReactComponent as MenuIcon } from "assets/svg/menu.svg";
import { Reorder, useDragControls, useMotionValue } from "framer-motion";
import { useRaisedShadow } from "./UseRaisedShadow";

type Props = {
  item: string;
};

export default function DraggableMenu({ item }: Props) {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      key={item}
      dragControls={dragControls}
      value={item}
      id={item}
      dragListener={false}
      style={{ boxShadow, y }}
      className="mb-2"
    >
      <li
        className={`border border-gray1 rounded-sm py-2 px-1 flex justify-between items-center transition-all duration-200`}
      >
        <span className="flex items-center justify-center">
          <span
            onPointerDown={(e) => {
              dragControls.start(e);
            }}
            className="cursor-move hover:bg-gray1 p-2 rounded-sm"
          >
            <MenuIcon />
          </span>
          <span className="text-[14px] font-semibold pl-1">{item}</span>
        </span>
        <span>
          <MoreSelect
            list={[
              {
                label: "수정하기",
                name: "수정하기",
                onClick: () => alert("개발중"),
              },
              {
                label: "삭제하기",
                name: "삭제하기",
                onClick: () => alert("개발중"),
              },
            ]}
          />
        </span>
      </li>
    </Reorder.Item>
  );
}
