import axios from 'axios';
import { getCookie, removeCookie } from './cookie';
//import mem from 'mem';

// export const BASE_URL = process.env.BASE_URL;
export const BASE_URL = 'https://sdwgu96zw9.execute-api.ap-northeast-2.amazonaws.com/Prod';
const REFRESH_URL = process.env.REFRESH_URL;

// 초기 interceptor 값
const instance = axios.create({
  baseURL: BASE_URL,
});

// ============================= request interceptor ===================================
instance.interceptors.request.use(async (config) => {
  if (!config.headers) return config;
  let token: string | null = null;

  // refresh token을 호출하는 경우는 refresh 토큰을 찾아서 token 값에 넣어준다.
  if (config.url === REFRESH_URL) {
    token = getCookie('refreshToken');
  } else {
    token = getCookie('accessToken');
  }

  // 토큰이 있으면 토큰을 header에 담아서 서버에 보낸다.
  if (token !== undefined) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (config.baseURL === BASE_URL) {
    if (config.data) {
      config.data = {
        ...config.data,
      };
    }
  }
  return config;
});

// 응답이 왔는데, 토큰이 만료되어 다시 리프레쉬 토큰으로 토큰 값 호출
// const getRefreshToken = mem(
//   async (): Promise<{
//     partnerAccessToken: string;
//     partnerRefreshToken: string;
//   } | void> => {
//     try {
//       console.log('================ 리프레쉬 토큰 재호출 ====================');
//       const REFRESH_TOKEN = getCookie('partnerRefreshToken');

//       const res = await instance.post<{
//         result: {
//           resultData: {
//             // 유효한 partnerRefreshToken일 경우 새로운 partnerAccessToken, partnerRefreshToken 반환
//             accessToken: string;
//             refreshToken: string;
//           };
//           resultStatus: {
//             code: number;
//             message: string;
//           };
//         };
//       }>(REFRESH_URL, {
//         refreshToken: REFRESH_TOKEN,
//       });

//       console.log('===========data=============', res);

//       setCookie('accessToken', res.data.result.resultData.accessToken);
//       setCookie('refreshToken', res.data.result.resultData.refreshToken);
//       return {
//         accessToken: res.data.result.resultData.accessToken,
//         refreshToken: res.data.result.resultData.refreshToken,
//       };
//     } catch (error) {
//       console.log(
//         '================ 토큰 오류 발생 getpartnerRefreshToken ================',
//       );
//       console.log('error : ', error);
//       deleteData();
//     }
//   },
//   { maxAge: 1000 },
// );
// ============================= response interceptor ===================================
instance.interceptors.response.use(
  // 응답 요청 성공했을 떄
  (response) => response,
  // 응답 요청 실패했을 떄
  async (err) => {
    // console.log("=============interceptor 에러 ===============", err);

    const {
      config,
      response: {
        status,
        data: { code, message, data },
      },
    } = err;

    const errData = err.response.data;
    const originalRequest = config;
    console.log('????', errData);
    /** 1 */
    //  로컬 스토리지에 토큰 자체가 없는데 요청을 보낸 경우 데이터 전체 삭제하고 메인페이지로 이동
    /** 2 */
    // 에세스 토큰이 만료되면 리프레쉬 토큰을 헤더에 담아서 다시 서버로 받아와서 보낸다.

    if (code === 1101 && message === '권한이 없습니다.') {
      deleteData(); // 데이터 삭제
    }

    if (code === 1000 && message === '로그인이 필요합니다.') {
      deleteData(); // 데이터 삭제
    }
    // return;
    if (code === 1205 && message === '만료된 토큰입니다.') {
      const REFRESH_TOKEN = getCookie('adminRefreshToken');
      if (!REFRESH_TOKEN) {
        deleteData(); // 데이터 삭제
      }

      originalRequest.sent = true;
      // const tokens: { partnerAccessToken: string; partnerRefreshToken: string } | void =
      //   await getpartnerRefreshToken(); // 갱선한 토큰

      // // console.log("🚀 tokens==>", tokens);

      // if (tokens?.partnerAccessToken && tokens?.partnerRefreshToken) {
      //   originalRequest.headers.Authorization = `Bearer ${tokens?.partnerAccessToken}`;
      //   return await axios(originalRequest);
      // }
    }

    return Promise.reject(errData);
  }
);

// 리프레쉬 토큰을 요청하였는데도 실패가 했다는 건, 리프레쉬 토큰도 만료가 되었다는 것이기에 로그아웃 처리를 진행한다.
const deleteData = async () => {
  // alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
  await removeCookie('accessToken');
  await removeCookie('refreshToken');
  window.location.href = '/login';
};

export default instance;
