import useModalStore from "store/useModalStore";
import { ReactComponent as BackIcon } from "assets/svg/back_icon.svg";
import ModalLayout from "components/modal/ModalLayout";

type Props = {};

export default function SettingModal({}: Props) {
  const { modals, setStatus } = useModalStore();
  const closeModal = () => {
    setStatus("menuSettingModal");
  };

  return (
    <ModalLayout isOpen={modals.menuSettingModal.isOpen} onClose={closeModal}>
      <div className="h-full flex flex-col justify-between items-center">
        <div className="w-full flex items-center justify-center relative">
          <button className="p-2 absolute left-0" onClick={closeModal}>
            <BackIcon />
          </button>
          <h2 className="text-base font-bold">메뉴 설정</h2>
        </div>
        <div></div>

        <button
          onClick={closeModal}
          className="bg-main1 w-full py-3 font-bold text-white text-lg rounded-lg"
        >
          설정
        </button>
      </div>
    </ModalLayout>
  );
}
