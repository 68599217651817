import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import Settings from 'pages/admin/Settings'; // Settings 컴포넌트 임포트 추가
import { BrowserRouter, Route, Routes } from 'react-router';
import AdminBeforeLoginLayout from 'components/common/AdminBeforeLoginLayout';
import AdminAfterLoginLayout from 'components/common/AdminAfterLoginLayout';
import Home from 'pages/admin/Home';
import Dashboard from './pages/admin/Dashboard';
import SignUp from './pages/admin/SignUp';
import Login from './pages/admin/Login';
import FindPassword from './pages/admin/FindPassword';
import Menu from 'pages/admin/Menu';
import MenuDetail from 'pages/admin/MenuDetail';
import MenuCreate from 'pages/admin/MenuCreate';
import Banners from 'pages/admin/Banners';
import Partners from 'pages/admin/Partners';
import RestaurantList from 'pages/service/RestaurantList';
import RestaurantMenuBoard from 'pages/service/RestaurantMenuBoard';
import RestaurantMenuList from 'pages/service/RestaurantMenuList';
import RestaurantMenuDetail from 'pages/service/RestaurantMenuDetail';
import RestaurantLayout from 'components/service/RestaurantLayout';
import MenuLayout from 'components/service/MenuLayout';
import Landing from 'pages/landing/Landing';
import { ErrorBoundary } from 'react-error-boundary';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div>
                There was an error!
                <button onClick={() => resetErrorBoundary()}>Try again</button>
              </div>
            )}
          >
            <BrowserRouter>
              <Routes>
                {/* 관리자 */}
                <Route element={<AdminBeforeLoginLayout />}>
                  <Route path='/admin/signUp' element={<SignUp />} />
                  <Route path='/admin/login' element={<Login />} />
                  <Route path='/admin/find/password' element={<FindPassword />} />
                </Route>
                <Route element={<AdminAfterLoginLayout />}>
                  <Route path='/admin' element={<Home />} />
                  <Route path='/admin/dashboard/:id' element={<Dashboard />} />
                  <Route path='/admin/menu' element={<Menu />} />
                  <Route path='/admin/menu/create' element={<MenuCreate />} />
                  <Route path='/admin/menu/:id' element={<MenuDetail />} />
                  <Route path='/admin/settings' element={<Settings />} />
                  <Route path='/admin/banners' element={<Banners />} />
                  <Route path='/admin/partners' element={<Partners />} />
                </Route>

                {/* 서비스(메뉴판) */}
                <Route element={<RestaurantLayout />}>
                  <Route path='restaurant' element={<RestaurantList />} />
                  <Route path='restaurant/:id' element={<RestaurantMenuList />} />
                </Route>
                <Route element={<MenuLayout />}>
                  <Route path='restaurant/menu/:id' element={<RestaurantMenuBoard />} />
                  <Route path='restaurant/menu/detail/:id' element={<RestaurantMenuDetail />} />
                </Route>

                {/* 랜딩 */}
                <Route path='/' element={<Landing />} />
              </Routes>
            </BrowserRouter>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </QueryClientProvider>
  );
}

export default App;
